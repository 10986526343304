import { svgIcon } from "@bitwarden/components";

export const RegistrationCheckEmailIcon = svgIcon`
<svg xmlns="http://www.w3.org/2000/svg" width="144" height="113" fill="none">
<g fill="#489C40" clip-path="url(#a)">
    <path class="tw-fill-primary-600" fill-rule="evenodd" d="M39.19 26.64c0-.765.62-1.384 1.383-1.384h18.785a1.383 1.383 0 0 1 0 2.766H40.573c-.764 0-1.383-.619-1.383-1.383ZM39.19 37.819c0-.764.62-1.383 1.383-1.383H58.81a1.383 1.383 0 0 1 0 2.766H40.573c-.764 0-1.383-.62-1.383-1.383ZM39.19 48.998c0-.764.62-1.383 1.383-1.383H61.82a1.383 1.383 0 0 1 0 2.766H40.573c-.764 0-1.383-.62-1.383-1.383Z" clip-rule="evenodd"/>
    <path class="tw-fill-primary-600" d="M94.576 60.504c0 .382.31.692.691.692 14.892 0 26.977-11.935 26.977-26.675a.692.692 0 0 0-1.383 0c0 13.96-11.451 25.292-25.594 25.292a.691.691 0 0 0-.691.691ZM68.982 35.213c.382 0 .692-.31.692-.692 0-13.96 11.45-25.291 25.593-25.291a.691.691 0 1 0 0-1.383c-14.89 0-26.976 11.935-26.976 26.674 0 .382.31.692.691.692Z"/>
    <path class="tw-fill-primary-600" fill-rule="evenodd" d="M95.387 2.926c-6.295 0-12.16 1.84-17.086 5.013l-1.136-.877a9.68 9.68 0 0 0-11.705-.096l-8.172 6.098a1.33 1.33 0 0 0-.064.05H33.393a4.149 4.149 0 0 0-4.149 4.15v16.753c-.07.035-.14.078-.205.127l-8.464 6.315a9.68 9.68 0 0 0-3.89 7.759v51.696c0 5.346 4.333 9.68 9.68 9.68h90.733c5.346 0 9.68-4.334 9.68-9.68v-26.48l8.099 8.098a4.84 4.84 0 0 0 6.845 0l.721-.721a4.84 4.84 0 0 0 0-6.845l-15.665-15.665V44.056c0-.587-.366-1.089-.882-1.29a31.666 31.666 0 0 0 1.086-8.245c0-17.45-14.146-31.595-31.595-31.595Zm28.625 52.61v-7.624a31.463 31.463 0 0 1-2.803 4.82l2.803 2.803Zm-4.51-.6a31.84 31.84 0 0 1-3.651 3.659l20.981 20.982c.81.81 2.124.81 2.934 0l.721-.722a2.073 2.073 0 0 0 0-2.933l-20.985-20.986Zm-5.851 5.37a31.434 31.434 0 0 1-16.653 5.77l-.023.014-9.463 5.427a15.205 15.205 0 0 1 2.167 1.666l33.149 30.601a6.876 6.876 0 0 0 1.184-3.87V70.668l-10.361-10.362ZM29.244 37.442l-7.014 5.234a6.914 6.914 0 0 0-2.588 3.925c.062.025.123.056.183.091l9.42 5.626V37.442Zm2.766 16.527 8.172 4.881c.124-.036.255-.056.39-.056h27.813a1.383 1.383 0 0 1 0 2.766H44.72l13.656 8.156a15.208 15.208 0 0 1 4.116-.567H79.36c1.801 0 3.571.32 5.233.928.095-.103.206-.193.334-.267l6.805-3.902C76.004 64.097 63.791 50.735 63.791 34.52a31.454 31.454 0 0 1 6.082-18.64h-36.48c-.764 0-1.383.619-1.383 1.383v36.705Zm40.135-40.851a31.78 31.78 0 0 1 3.8-3.504l-.47-.363a6.914 6.914 0 0 0-8.36-.068l-5.27 3.932h10.212c.03 0 .059 0 .088.003ZM66.557 34.52c0-15.922 12.907-28.83 28.83-28.83 15.922 0 28.829 12.908 28.829 28.83 0 15.922-12.907 28.83-28.83 28.83-15.922 0-28.83-12.908-28.83-28.83ZM19.45 49.691v50.223c0 1.418.428 2.738 1.16 3.835l31.298-30.315a15.21 15.21 0 0 1 3.266-2.41L19.45 49.69Zm3.124 56.007a6.888 6.888 0 0 0 3.79 1.13h90.734a6.879 6.879 0 0 0 3.752-1.106L87.803 75.216a12.446 12.446 0 0 0-8.442-3.301H62.49a12.446 12.446 0 0 0-8.658 3.506l-31.259 30.277Z" clip-rule="evenodd"/></g><defs><clipPath id="a">
    <path class="tw-fill-primary-600" fill="#fff" d="M.09 0h143.82v112.705H.09z"/>
    </clipPath>
    </defs>
</svg>`;
